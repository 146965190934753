import usersService from './UsersServices'
import UserSettingServices from './UserSettingServices'
// import packageService from './packageService'

const services = {
  Users: usersService,
  UserSetting: UserSettingServices,
  // package: packageService,
}

export const ServiceFactory = {
  get: (name) => services[name],
}
